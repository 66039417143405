import React, { } from "react"
import "../../../assets/styles/_index.scss"
import Favicon from "react-favicon"
import AarraDeveloper from "../../../components/developers/aarradeveloper/AarraDeveloper";

const favicon_logo = require("../../../images/favicon.png");

const AarraPage = () => (
  <>
    <Favicon url={favicon_logo} />

    <AarraDeveloper />
  </>
)

export default AarraPage
